import React from 'react';
import { connect } from 'react-redux'
import { Layout, Menu, Row, Col, Divider } from 'antd';

import './Sidebar.css';
import logoMan from '../../assets/images/logo-man.png';
import { Link } from "react-router-dom";
import { BrowserView } from 'react-device-detect';

const { Sider } = Layout;

class Sidebar extends React.Component {
  state = {
    openKey: 0,
    selectedKey: 0,
  };

  getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  render() {
    let pathname = window.location.pathname;
    const path = pathname.split("/");
    let openKey = this.state.openKey
    if (path.length > 0) {
      openKey = path[1]
    }
    if (this.state.collapsed === true) {
      openKey = 0
    }
    if (pathname === "/" || pathname === "") {
      pathname = "/"
    }
    if (pathname.includes("/create")) {
      pathname = pathname.replace("/create", "");
    }

    const lastSegment = pathname.split("/").pop();
    if (pathname.includes("/pelajaran/detail")) {
      pathname = pathname.replace("/pelajaran/detail/" + lastSegment, "");
    } else if (pathname.includes("/mengajar/detail")) {
      pathname = pathname.replace("/mengajar/detail/" + lastSegment, "");
    } else if (pathname.includes("/pelanggaran-siswa/detail")) {
      pathname = pathname.replace("/pelanggaran-siswa/detail/" + path[4] + "/" + lastSegment, "/pelanggaran-siswa");
    } else if (pathname.includes("/penghargaan-siswa/detail")) {
      pathname = pathname.replace("/penghargaan-siswa/detail/" + path[4] + "/" + lastSegment, "/penghargaan-siswa");
    }

    if (pathname.includes("/detail")) {
      pathname = pathname.replace("/detail/" + lastSegment, "");
    }

    const menu = this.props.menu
    const items = []
    for (let i = 0; i < menu.length; i++) {
      const subItems = []
      if (menu[i].children) {
        if (menu[i].children.length > 0) {
          for (let j = 0; j < menu[i].children.length; j++) {
            const subMenu = menu[i].children[j]
            subItems.push(this.getItem(<Link to={subMenu.path}>{subMenu.name}</Link>, (subMenu.path ? subMenu.path : i), <span className="icon-sidebar icon-circle" style={{ fontSize: "60%" }}></span>))
          }
        }
      }
      items.push(this.getItem(<Link to={menu[i].path}>{menu[i].name}</Link>, menu[i].path ? menu[i].path : i, menu[i].icon ? <span className={`icon-sidebar ${menu[i].icon}`}></span> : <span className="icon-sidebar icon-dashboard"></span>, subItems.length > 0 ? subItems : null))
    }

    let styleProfile = {
      margin: "-54px auto 0px",
      width: "fit-content"
    }
    let styleProfileImage = {
      backgroundImage: `url("` + logoMan + `")`,
      backgroundColor: "rgb(255, 255, 255)",
      padding: "8px",
      borderRadius: "100%",
      width: "80px",
      overflow: "hidden",
      height: "80px",
      backgroundSize: "56px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      border: "3px solid rgb(224 224 224 / 50%)"
    }
    if (this.props.menuCollapse) {
      styleProfile = {
        margin: "24px auto 0px",
        width: "fit-content"
      }
      styleProfileImage = {
        backgroundImage: `url("` + logoMan + `")`,
        backgroundColor: "rgb(255, 255, 255)",
        padding: "8px",
        borderRadius: "100%",
        width: "40px",
        overflow: "hidden",
        height: "40px",
        backgroundSize: "36px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        border: "3px solid rgb(224 224 224 / 50%)"
      }
    }

    return (
        <BrowserView>
          <Sider collapsed={this.props.menuCollapse} width={270} style={{
            height: "100%",
            marginTop: -8
          }}>
            <Row gutter={[16, 16]}>
              {
                this.props.menuCollapse ? "" :
                  <Col xs={24}>
                    <div className='sidebar-profile'>
                      <div className='sidebar-grid-container'>
                      </div>
                    </div>
                  </Col>
              }
              <Col xs={24}>
                <div style={styleProfile}>
                  <div style={styleProfileImage}>
                  </div>
                </div>
              </Col>
              <Col xs={24} style={{
                textAlign: "center"
              }}>
                <div style={this.props.menuCollapse ? {
                  fontSize: '50%'
                } : {
                  fontWeight: "bold"
                }}>{this.props.privilege.profile.name}</div>
                <div style={this.props.menuCollapse ? {
                  fontSize: '50%',
                  color: "#8C8C8C"
                } : {
                  color: "#8C8C8C"
                }}>{this.props.privilege.profile.roles}</div>
                <Divider />
              </Col>
            </Row>
            <Menu
              defaultSelectedKeys={[pathname]}
              defaultOpenKeys={[openKey]}
              mode="inline"
              items={items} />
          </Sider>
        </BrowserView>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    privilege: state.privilege,
    menu: state.menu,
    menuCollapse: state.menuCollapse,
    isMobile: state.isMobile,
  }
}

export default connect(mapStateToProps)(Sidebar);
